<script>
import { app } from '@/main'
import { mapActions } from 'vuex'
import { required, minLength } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'

import { APPLICABILITY_OPTIONS_FIELD } from '../constants'

const TRANSLATION = {
  formData: {
    name: app.$t('notifications.mediation-plan:manage.section.data-plans.name'),
    nameUnderDescription: app.$t('notifications.mediation-plan:manage.section.data-plans.name.under-description'),
    description: app.$t('notifications.mediation-plan:manage.section.data-plans.description'),
    descriptionUnderDescription: app.$t('notifications.mediation-plan:manage.section.data-plans.description.under-description'),
    applicability: app.$t('notifications.mediation-plan:manage.section.data-plans.applicability'),
    applicabilityUnderDescription: app.$t('notifications.mediation-plan:manage.section.data-plans.applicability.under-description'),
    dispatchTime: app.$t('notifications.mediation-plan:manage.section.data-plans.dispatchTime'),
    dispatchTimeUnderDescription: app.$t('notifications.mediation-plan:manage.section.data-plans.dispatchTime.under-description')
  },

  feedback: {
    validationError: app.$t('global:feedback.validation.error')
  }
}

export default {
  name: 'ManageTabDataPlan',

  components: {
    InputField: () => import('@/components/general/InputField'),
    SelectField: () => import('@/components/general/SelectField')
  },

  directives: {
    mask
  },

  data () {
    return {
      formData: {
        name: null,
        description: null,
        applicability: null,
        dispatchTime: null
      }
    }
  },

  validations: {
    formData: {
      name: { required },
      description: { required },
      applicability: { required },
      dispatchTime: {
        required,
        minLength: minLength(2),
        shouldBeInRange: function (time) {
          if (!time) return false

          if (time.length === 5) {
            const hour = time.split(':')[0]

            return (hour >= 0 && hour < 24)
          } else {
            return (time >= 0 && time < 24)
          }
        }
      }
    }
  },

  computed: {
    isEditing () {
      return !!this.$route.params.id
    }
  },

  watch: {
    formData: {
      deep: true,
      handler () {
        this.$emit('mergeFormDataTab', this.formData)
      }
    },

    '$v.$invalid': {
      deep: true,
      handler () {
        const tab = 'tabDataPlan'
        const isValid = !this.$v.$invalid

        this.$emit('validationStatus', {
          tab,
          isValid
        })
      }
    }
  },

  created () {
    this.setup()
  },

  methods: {
    ...mapActions([ 'setFeedback' ]),

    setup () {
      this.TRANSLATION = TRANSLATION
      this.APPLICABILITY_OPTIONS_FIELD = APPLICABILITY_OPTIONS_FIELD
    },

    // eslint-disable-next-line vue/no-unused-properties
    checkIsInvalidToParent () {
      this.$v.$touch()

      const isInvalid = this.$v.$invalid

      if (isInvalid) {
        this.setFeedback({ message: TRANSLATION.feedback.validationError })
      }

      return isInvalid
    }
  }

}
</script>

<template>
  <form
    class="form"
    @submit.prevent
  >
    <InputField
      v-model="formData.name"
      :label="TRANSLATION.formData.name"
      :under-description="TRANSLATION.formData.nameUnderDescription"
      :validation="$v.formData.name"
      :counter="75"
    />

    <InputField
      v-model="formData.description"
      :label="TRANSLATION.formData.description"
      :under-description="TRANSLATION.formData.descriptionUnderDescription"
      :validation="$v.formData.description"
      :counter="200"
    />

    <SelectField
      v-model="formData.applicability"
      :label="TRANSLATION.formData.applicability"
      :under-description="TRANSLATION.formData.applicabilityUnderDescription"
      :items="APPLICABILITY_OPTIONS_FIELD"
      :validation="$v.formData.applicability"
      :disabled="isEditing"
    />

    <InputField
      v-model="formData.dispatchTime"
      mask="##:00"
      :label="TRANSLATION.formData.dispatchTime"
      :under-description="TRANSLATION.formData.dispatchTimeUnderDescription"
      :validation="$v.formData.dispatchTime"
      type="text"
    />
  </form>
</template>
